import React, { useState, useEffect } from "react";
import Video from "../assets/img/prelouder.MP4";
import VideoGif from "../assets/img/prelouder.gif";

function Preloader() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 3500); //3500 Установите время задержки по вашему усмотрению

    return () => clearTimeout(timeout);
  }, []);
  return (
    <div className={`preloader bg-black ${visible ? 'visible' : 'hidden'}`}>
      <div className=" sm:p-[450px]"> 
      {/* <video
      autoPlay muted loop className="video" playbackRate={1}>
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src={VideoGif} alt="Preloader" className="gif-image" />
      </div>
    </div>
  );
}

export default Preloader;
