import React, { useState, useEffect } from "react";
import TopBar from "../Components/TopBar";
import Reviews1 from "../assets/img/Reviews/Reviews1.svg";
import Reviews2 from "../assets/img/Reviews/Reviews2.svg";
import PopupFormRev from "../Components/PopupFormRev";
import { useTranslation } from "react-i18next";

function Reviews() {
  const { t, i18n } = useTranslation();
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    const url = t("rew-data");
    fetch(url, {
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Выводим загруженные данные в консоль
        setReviews(data);
        restoreLikesFromLocalStorage(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [t]);

  useEffect(() => {
    if (reviews !== null) {
      saveLikesToLocalStorage(reviews);
    }
  }, [reviews]);

  const restoreLikesFromLocalStorage = (reviews) => {
    const likesString = localStorage.getItem("likes");
    if (likesString) {
      const likes = JSON.parse(likesString);
      const updatedReviews = reviews.map((review, index) => {
        const likeStatus = {
          dislike: likes[index] === "dislike",
          like: likes[index] === "like",
        };
        return { ...review, likeStatus };
      });
      setReviews(updatedReviews);
    }
  };


  const handleDislikeClick = (index) => {
    setReviews((prevReviews) => {
      const updatedReviews = [...prevReviews];
      const newStatus = { dislike: true, like: false };
      updatedReviews[index].likeStatus = newStatus;
      saveLikesToLocalStorage(updatedReviews);
      return updatedReviews;
    });
  };
  
  const handleLikeClick = (index) => {
    setReviews((prevReviews) => {
      const updatedReviews = [...prevReviews];
      const newStatus = { dislike: false, like: true };
      updatedReviews[index].likeStatus = newStatus;
      saveLikesToLocalStorage(updatedReviews);
      return updatedReviews;
    });
  };
  
  const saveLikesToLocalStorage = (reviews) => {
    const likes = reviews.map((review) => {
      if (review.likeStatus && review.likeStatus.dislike) return "dislike";
      if (review.likeStatus && review.likeStatus.like) return "like";
      return null;
    });
    localStorage.setItem("likes", JSON.stringify(likes));
  };
  

  return (
    <>
      <section className="h-full pt-10 pb-20 relative m-0 main__bg">
        <div className="container pt-16 px-4 relative z-[1]">
          <TopBar title={t("Reviews")} link="/service" />

          <div className="slideWrapper mt-10 flex gap-5 flex-wrap">
            {reviews && reviews.map((review, index) => (
              <div
                key={index}
                className="slide w-full max-w-[340px] pt-7 flex flex-col"
              >
                <div className="bg-[#D9D9D9] rounded-2xl p-7 h-full">
                  <div className="flex gap-[14px] ">
                    <img className="w-[16px]" src={Reviews2} alt="Review" />
                    <p className="rev__name text-black">{review.name}</p>
                  </div>
                  <p className="text-black mt-4">{review.review}</p>
                </div>

                <div className="flex gap-5 mt-3 pl-7 ">
                  <div>
                    <svg
                      className={`cursor-pointer ${
                        review.likeStatus && review.likeStatus.like ? "" : "hidden"
                      }`}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="#C8A26D"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleDislikeClick(index)}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 10C0 4.48 4.47 0 9.99 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 9.99 20C4.47 20 0 15.52 0 10ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10ZM15.12 12C14.32 14.05 12.33 15.5 10 15.5C7.67 15.5 5.68 14.05 4.88 12H6.55C7.25 13.19 8.52 14 10 14C11.48 14 12.76 13.19 13.45 12H15.12ZM6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9ZM13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z"
                      />
                    </svg>

                    <svg
                      className={`cursor-pointer ${
                        review.likeStatus && review.likeStatus.dislike ? "" : "hidden"
                      }`}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="#C8A26D"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleLikeClick(index)}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM8 7.5C8 8.32843 7.32843 9 6.5 9C5.67157 9 5 8.32843 5 7.5C5 6.67157 5.67157 6 6.5 6C7.32843 6 8 6.67157 8 7.5ZM15 7.5C15 8.32843 14.3284 9 13.5 9C12.6716 9 12 8.32843 12 7.5C12 6.67157 12.6716 6 13.5 6C14.3284 6 15 6.67157 15 7.5ZM5.00023 14.5C5.80023 12.45 7.79024 11 10.1202 11C12.4502 11 14.4402 12.45 15.2402 14.5H13.5702C12.8702 13.31 11.6002 12.5 10.1202 12.5C8.64023 12.5 7.36023 13.31 6.67023 14.5H5.00023Z"
                      />
                    </svg>
                  </div>
                  <svg
                    className="cursor-pointer"
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleDislikeClick(index)}
                  >
                    <path
                      d="M14 0H5C4.17 0 3.46 0.5 3.16 1.22L0.14 8.27C0.05 8.5 0 8.74 0 9V11C0 12.1 0.9 13 2 13H8.31L7.36 17.57L7.33 17.89C7.33 18.3 7.5 18.68 7.77 18.95L8.83 20L15.41 13.41C15.78 13.05 16 12.55 16 12V2C16 0.9 15.1 0 14 0ZM14 12L9.66 16.34L10.77 11H2V9L5 2H14V12ZM18 0H22V12H18V0Z"
                      fill={review.likeStatus && review.likeStatus.dislike ? "#C8A26D" : "white"}

                    />
                  </svg>

                  <svg
                    className="cursor-pointer"
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleLikeClick(index)}
                  >
                    <path
                      d="M20 7H13.69L14.64 2.43L14.67 2.11C14.67 1.7 14.5 1.32 14.23 1.05L13.17 0L6.59 6.59C6.22 6.95 6 7.45 6 8V18C6 19.1 6.9 20 8 20H17C17.83 20 18.54 19.5 18.84 18.78L21.86 11.73C21.95 11.5 22 11.26 22 11V9C22 7.9 21.1 7 20 7ZM20 11L17 18H8V8L12.34 3.66L11.23 9H20V11ZM0 8H4V20H0V8Z"
                      fill={review.likeStatus && review.likeStatus.like ? "#C8A26D" : "white"}
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div>
          <div
            className=" fixed bottom-0 right-[0] bg-black bg-opacity-30 px-4 pb-3 sm:pb-5 pt-2  left-0"
            style={{ boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.4)" }}
          >
            <div className=" flex justify-center sm:justify-end">
              <div className=" flex gap-[15px] justify-center sm:justify-start">
                <a
                  className="w-[164px] h-[44px] flex justify-center items-center border border-active-0 rounded-full font-bold text-[15px] hover:bg-active-0 hover:text-black bg-[#27292E]"
                  href="/"
                >
                  {t("place-4")}
                  
                </a>
                <PopupFormRev />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Reviews;