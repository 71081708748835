import { useState } from "react";
import TopBar from "../Components/TopBar";
import TelegramScript from "../Components/TelegramScript";
import { useTranslation } from "react-i18next";

function Consultation() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className=" h-full pt-10 pb-20 relative m-0 main__bg">
        <div className="container pt-16 px-4 relative z-[1]">
          <TopBar title={t("Consultation-topBar")} link="/service" />
          <TelegramScript title={t("Consultation-tel")}/>
        </div>
      </section>
    </>
  );
}

export default Consultation;
