import { useState } from "react";
import TelegramScript from "./TelegramScript";
import { useTranslation } from "react-i18next";

function PopupForm({ buttonText }) {
  const [isCloseVisible, setIsCloseVisible] = useState(true);

  const toggleCloseVisibility = () => {
    setIsCloseVisible(!isCloseVisible); // Изменяем состояние isCloseVisible
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        className=" btn__cons w-[164px] h-[44px] flex justify-center items-center border border-[#1d1f22] hover:border-white rounded-full font-bold text-[15px]"
        onClick={toggleCloseVisibility}
      >
        {buttonText}
      </button>
      <div
        className={`PopupForm fixed top-0 left-0 w-full h-full  z-10  justify-center popup__bg ${
          isCloseVisible ? "hidden" : "flex"
        }`}
      >
        <div className="container pt-16 px-4  top-0  w-full h-full  flex justify-center items-center">
          <div className=" relative ">
            <div
              onClick={toggleCloseVisibility}
              className="Close w-9 sm:w-11 h-11 absolute right-5 top-4  hover:rotate-[90deg] transition-all duration-300 cursor-pointer z-[1]"
            >
              <span className="absolute w-full bg-white h-0.5 top-1/2 left-0 rounded-xl transition-all duration-300 rotate-[45deg] "></span>
              <span className="absolute w-full bg-white h-0.5 top-1/2 left-0 rounded-xl transition-all duration-300 -rotate-[45deg] "></span>
            </div>
            <TelegramScript title={t("Consultation-tel")} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupForm;
