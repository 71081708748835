
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperBtn from "../assets/img/swiper-btn.svg";
import SwiperBtnNext from "../assets/img/swiper-btn-next.svg";
import SwiperBtnObod from "../assets/img/swiper-btn-obod.svg";
import Servis1 from "../assets/img/servis1.png";
import Servis2 from "../assets/img/servis2.png";
import Servis3 from "../assets/img/servis3.png";
import Servis4 from "../assets/img/servis4.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";

function Service() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className=" main__bg pt-10 pb-20 relative linear__bg">
        <div className=" container pt-10 sm:pt-14 px-4">
          <div className=" flex gap-9 relative items-center justify-center sm:justify-start">
            <a href="/" className=" hidden sm:inline-block w-[62px] h-[62px] relative">
              <div className="prev left-0 ">
                <div className="Elips1"></div>
                <div className="Elips2"></div>
                <img src={SwiperBtnObod} className="Elips3" />
                <img src={SwiperBtnNext} className="sw__arrow" />
              </div>
            </a>
            <h1 className=" font-kalinov text-active-0 text-3xl sm:text-6xl">
            {t("Services")}
            </h1>
            
          </div>
          <div className=" relative mt-10 ">
            <Swiper
              className="mySwiper "
              modules={[Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              navigation={{
                clickable: true,
                nextEl: ".swiper-button-next__custom",
                prevEl: ".swiper-button-prev__custom",
              }}
              spaceBetween={32}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              <SwiperSlide className=" rounded-3xl">
                <a href="/energy" className="servis__slide">
                  <img
                    src={Servis1}
                    className=" rounded-2xl h-full sm:max-h-[450px] object-cover absolute sm:relative "
                  />
                  <h2 className=" relative pt-12 text-2xl z-[1] block px-5 sm:px-0 font-bold">
                  {t("service-card-1-title")}
                  </h2>
                  <p className=" relative pt-4 z-[1] block px-5 sm:px-0 pb-5 text-sm sm:text-base">
                  {t("service-card-1-text")}
                  </p>
                </a>
              </SwiperSlide>

              <SwiperSlide className=" rounded-3xl">
                <a href="/rpt" className="servis__slide">
                  <img
                    src={Servis2}
                    className=" rounded-2xl h-full sm:max-h-[450px] object-cover absolute sm:relative "
                  />
                  <h2 className=" relative pt-12 text-2xl z-[1] block px-5 sm:px-0 font-bold">
                  {t("service-card-2-title")}
                    
                  </h2>
                  <p className=" relative pt-4 z-[1] block px-5 sm:px-0 pb-5 text-sm sm:text-base">
                  {t("service-card-2-text")}
                  </p>
                </a>
              </SwiperSlide>

              <SwiperSlide className=" rounded-3xl">
                <a href="/retreats" className="servis__slide">
                  <img
                    src={Servis3}
                    className=" rounded-2xl h-full sm:max-h-[450px] object-cover absolute sm:relative "
                  />
                  <h2 className=" relative pt-12 text-2xl z-[1] block px-5 sm:px-0 font-bold">
                  {t("service-card-3-title")}
                  </h2>
                  <p className=" relative pt-4 z-[1] block px-5 sm:px-0 pb-5 text-sm sm:text-base">
                  {t("service-card-3-text")}
                  </p>
                </a>
              </SwiperSlide>

              <SwiperSlide className=" rounded-3xl">
                <a href="/contacts" className="servis__slide">
                  <img
                    src={Servis4}
                    className=" rounded-2xl h-full sm:max-h-[450px] object-cover absolute sm:relative bg-white sm:bg-transparent"
                  />
                  <h2 className=" relative pt-12 text-2xl z-[1] block px-5 sm:px-0 text-black sm:text-white font-bold">
                  {t("service-card-4-title")}
                  </h2>
                  <p className=" relative pt-4 z-[1] block px-5 sm:px-0 pb-5 text-sm sm:text-base text-black sm:text-white">
                  {t("service-card-4-text")}
                    
                  </p>
                </a>
              </SwiperSlide>
            </Swiper>
            <div className="swiper-button-prev__custom prev hidden">
              <div className="Elips1"></div>
              <div className="Elips2"></div>
              <img src={SwiperBtnObod} className="Elips3" />
              <img src={SwiperBtnNext} className="sw__arrow" />
            </div>
            <div className="swiper-button-next__custom next hidden">
              <div className="Elips1"></div>
              <div className="Elips2"></div>
              <img src={SwiperBtnObod} className="Elips3" />
              <img src={SwiperBtn} className="sw__arrow" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Service;
