import React, { useRef, useEffect, useState, Component } from "react";
import main1 from "../assets/img/main.png";
import logo1 from "../assets/img/logo-img.png";
import logo2 from "../assets/img/logo-text.png";
import HeadImg from "../assets/img/head__img.png";
import Play from "../assets/img/play.svg";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import VideoTest from "../assets/img/video3.mov";
import { useTranslation } from "react-i18next";

function Home() {
  const [videoStarted, setVideoStarted] = useState(false);

  useEffect(() => {
    const video = document.getElementById("videoPlayer");
    if (video) {
      video.addEventListener("play", handleVideoPlay);
    }
    return () => {
      if (video) {
        video.removeEventListener("play", handleVideoPlay);
      }
    };
  }, []);

  const handleVideoPlay = () => {
    setVideoStarted(true);
  };

  const handlePlayButtonClick = () => {
    const video = document.getElementById("videoPlayer");
    if (video) {
      video.play();
    }
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="main__bg pt-20 pb-10 sm:pb-20 px-4">
        <div className="container grid grid-cols-1 sm:grid-cols-2 sm:gap-4 pt-2 sm:pt-14 ">
          <div className=" order-1 sm:order-none">
            <div className=" flex flex-col items-center max-w-48 pt-0 sm:pt-0 ">
              <img src={logo1} className=" hidden sm:block  w-48" />
              <img src={logo2} className=" hidden sm:block  w-44 -mt-8" />
            </div>
            <p className="  mt-6 sm:mt-10 text-sm sm:text-lg ">
              {t("home-text")}
            </p>
            <div className="flex justify-center sm:block">
              <Link to="/service" className="button__main mt-6 sm:mt-10 ">
                <div className=" w-full h-full flex justify-center items-center text-white font-bold  transition-all duration-300">
                  {t("Services")}
                </div>
              </Link>
            </div>
          </div>
          <div className=" relative ">
            <div className="main__linear w-full h-full absolute top-10 left-16"></div>
            <div className=" relative">
              {!videoStarted && (
                <button
                  className=" absolute w-full  bottom-[13%] z-[1] py-3  flex justify-center"
                  onClick={handlePlayButtonClick}
                >
                  <div className="flex items-center justify-center gap-2 bg-active-0 max-w-[200px] py-3 px-9 rounded-3xl font-bold border border-black no">
                    <img className="w-[40px]" src={Play} /> Play Video
                  </div>
                </button>
              )}
              <video
                id="videoPlayer"
                controls
                autoPlay
                className="video bg-black object-cover rounded-2xl border border-active-0 border-solid max-w-full sm:max-w-auto sm:min-w-[400px] max-h-[400px] w-[100%] sm:w-auto sm:max-h-[600px] m-auto"
                playbackRate={1}
                controlsList="nodownload"
                onPlay={handleVideoPlay}
              >
                <source src={VideoTest} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            {/* <img
              src={HeadImg}
              className=" rounded-2xl  object-cover max-w-full sm:max-w-[550px] sm:ml-[auto] max-h-[430px] sm:max-h-[600px] object-top"
              style={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)" }}
            /> */}
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
