import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ruTranslation from './1ru.json';
import uaTranslation from './1ua.json';
import enTranslation from './1en.json';

// import reviewRu from '../../public/reviews/reviewRu.json';


const languageKey = 'selectedLanguage';

const selectedLanguage = localStorage.getItem(languageKey);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ru: {
        translation: { ...ruTranslation} //, ...reviewRu
      },
      ua: {
        translation: uaTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
    lng: selectedLanguage || 'ru', // Если сохраненного языка нет, используется 'ru'
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem(languageKey, lng); // Сохраняем выбранный язык в localStorage
});

export default i18n;



