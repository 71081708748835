import React from 'react';
import Home from './Pages/Home'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Service from './Pages/Service';
import Header from './Components/Header';
import Energy from './Pages/Energy/Energy';
import Retreats from './Pages/Retreats/Retreats';
import Contacts from './Pages/Contact/Contacts';

import Rpt from './Pages/Rpt/Rpt';
import RptFocused from './Pages/Rpt/Rpt-focused';
import RptComprehensive from './Pages/Rpt/Rpt-comprehensive';
import Consultation from './Pages/Consultation';
import Reviews from './Pages/Reviews';
import ThankYou from './Pages/ThankYou';
import ThankYouRev from './Pages/ThankYouRev';
import 'animate.css';

import withLoader from "./Components/withLoader";
import i18n from './Locales/i18n';
import Anons from './Pages/Retreats/Anons';




function App() {
  const HomeWithLoader = withLoader(Home, true); // Показывать прелоадер на домашней странице
  const ServiceWithLoader = withLoader(Service, true); // Не показывать прелоадер на странице услуг
  return (
        <>
      
       
       <Header />
      <Routes>
        <Route path="/" element={<HomeWithLoader />} /> {/* Используй HomeWithLoader вместо Home */}
        <Route path="/service" element={<ServiceWithLoader />} /> {/* Используй ServiceWithLoader вместо Service */}
        <Route path="/energy" element={<Energy />} />
        <Route path="/retreats" element={<Retreats />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/consultation" element={<Consultation />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/thank-you-rev" element={<ThankYouRev />} />
        <Route path="/anons" element={<Anons />} />
        
        
        <Route path="/rpt" element={<Rpt />} />
        <Route path="/rpt-focused" element={<RptFocused />} />
        <Route path="/rpt-comprehensive" element={<RptComprehensive />} />
       
      </Routes>
    </>
  );
}

export default App;
