import TopBar from "../../Components/TopBar";
import Rtp3 from "../../assets/img/rpt/rtp3.png";
import Buttons from "../../Components/Buttons";
import { useTranslation } from "react-i18next";
import SwiperBtnObod from "../../assets/img/swiper-btn-obod.svg";
import SwiperBtnNext from "../../assets/img/swiper-btn-next.svg";

function RptFocused() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className=" h-full pt-10 pb-2 relative m-0 bg-[#191A1D]">
        <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
          <div className=" flex gap-10 relative items-center justify-between sm:justify-start">
            <a href="/rpt" className=" inline-block w-[62px] h-[62px] relative">
              <div className="prev left-0 ">
                <div className="Elips1"></div>
                <div className="Elips2"></div>
                <img src={SwiperBtnObod} className="Elips3" />
                <img src={SwiperBtnNext} className="sw__arrow" />
              </div>
            </a>
            <h1 className=" font-kalinov text-active-0 text-2xl sm:text-4xl whitespace-nowrap">
              {t("rpt-focus-topBar-1")} <br className="sm:hidden" />
              {t("rpt-focus-topBar-2")}
            </h1>
            <div className="w-[30px] sm:hidden"></div>
          </div>

          <div className="grid grid-cols-12 gap-[0]">
            <div className=" col-span-12 sm:col-span-5">
              <p className=" pt-8 text-sm sm:text-xl animate__animated animate__fadeInUp">
                {t("rpt-focus-text-1")} <br />
              </p>

              <div className="hidden sm:block"><Buttons /></div>
              <div className=" absolute w-full bottom-8 left-0 z-[1] flex justify-center sm:hidden"><Buttons /></div>
              
            </div>
            <div className="col-span-12  sm:col-span-7  items-center flex mt-5 sm:mt-0 animate__animated animate__fadeInRight">
              <img
                className=" -mt-5  rounded-3xl object-contain max-h-[700px] "
                src={Rtp3}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RptFocused;
