import Retreats1 from "../../assets/img/Retreats/Retreats1.png";
import Buttons from "../../Components/Buttons";
import PopupForm from "../../Components/PopupForm";
import PopupFormRet from "../../Components/PopupFormRet";
import TopBar from "../../Components/TopBar";
import { useTranslation } from "react-i18next";

function Retreats() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className=" h-full pt-10 pb-2 relative m-0 bg-[#191A1D]">
        <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
          <TopBar title={t("retreats-topBar")} link="/service" />
          <div className="grid grid-cols-12 gap-[0]">
            <div className=" col-span-12 sm:col-span-5">
              <p className=" pt-8 text-sm sm:text-xl">
                {t("retreats-text-1")} <br />
                <br />
                {t("retreats-text-2")}
              </p>

              <div className=" mt-10 sm:mt-16 flex gap-[15px] justify-center sm:justify-start">
                <a
                  className="w-[164px] h-[44px] flex justify-center items-center border border-active-0 rounded-full font-bold text-[15px] hover:bg-active-0 hover:text-black"
                  href="/reviews"
                >
                  {t("Reviews")}
                </a>
                <a
                  className=" btn__cons w-[164px] h-[44px] flex justify-center items-center border border-[#1d1f22] hover:border-white rounded-full font-bold text-[15px]"
                  href="/anons"
                >
                  {t("announcements")}
                </a>
              </div>
            </div>
            <div className="col-span-12  sm:col-span-7  items-center flex mt-5 sm:mt-0">
              <img
                className="  rounded-3xl object-contain max-h-[700px] "
                src={Retreats1}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Retreats;
