import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TelegramScriptRev({ title }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [review, setReview] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Простая валидация полей
    if (!name.trim() || !review.trim()) {
      setError("Пожалуйста, заполните все поля");
      return;
    }

    // Формируем текст сообщения
    const messageText = `Новый отзыв:\nИмя: ${name}\nОтзыв: ${review}`;

    // Формируем данные для отправки
    const formData = new FormData();
    formData.append("chat_id", "-1002124105508"); // ID вашего чата в Telegram
    formData.append("text", messageText);

    // Отправляем запрос на API Telegram
    try {
      const response = await fetch(
        "https://api.telegram.org/bot6800174655:AAEJsFPt2V1AdO_SvBhEKEfqBGP2N66omGc/sendMessage",
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();
      console.log(responseData);
      navigate("/thank-you-rev");
    } catch (error) {
      console.error("Ошибка отправки сообщения:", error);
    }
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
    setError(""); // Сбрасываем ошибку при изменении значения поля
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
    setError(""); // Сбрасываем ошибку при изменении значения поля
  };

  const handleChangeReview = (event) => {
    setReview(event.target.value);
    setError(""); // Сбрасываем ошибку при изменении значения поля
  };

  const { t, i18n } = useTranslation();
  return (
    <div className="flex justify-center">
      <form
        onSubmit={handleSubmit}
        className={` form__bg w-full max-w-[700px] rounded-3xl p-[20px] py-[50px] sm:p-[50px] relative`}
      >
        <h2 className="font-bold text-[22px] sm:text-[32px] text-center">
          {title}
        </h2>
        <input
          className="mt-8 sm:mt-16 w-full bg-black border border-active-0 p-5 rounded-xl outline-none text-1xl bg-opacity-10 focus:bg-black focus:bg-opacity-30"
          type="text"
          id="nameInput"
          name="name"
          value={name}
          onChange={handleChangeName}
          placeholder={t("place-2")}
        />
        {/* <input
          className="mt-8 sm:mt-16 w-full bg-black border border-active-0 p-5 rounded-xl outline-none text-1xl bg-opacity-10 focus:bg-black focus:bg-opacity-30"
          type="tel"
          id="phoneInput"
          name="phone"
          value={phone}
          onChange={handleChangePhone}
          placeholder="Введите ваш номер телефона..."
        /> */}
        <textarea
          className="mt-8 sm:mt-16 w-full bg-black border border-active-0 p-5 rounded-xl outline-none text-1xl bg-opacity-10 focus:bg-black focus:bg-opacity-30"
          id="reviewInput"
          name="review"
          value={review}
          onChange={handleChangeReview}
          placeholder={t("place-3")}
        />
        {error && <p className="text-red-500">{error}</p>}{" "}
        {/* Отображаем ошибку, если есть */}
        <div className="flex justify-center">
          <div className="mt-10 flex gap-[15px] justify-center sm:justify-start">
            <button
              type="submit"
              className="btn__cons w-[250px] h-[60px] flex justify-center items-center border border-[#1d1f22] hover:border-white rounded-full font-bold text-[15px]"
            >
              Ок
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TelegramScriptRev;
