import TopBar from "../Components/TopBar";
import Logo from "../assets/img/logo-img2.png";
import { useTranslation } from "react-i18next";

function ThankYou() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="h-full pt-10 pb-20 relative m-0 main__bg">
        <div className="container pt-16 px-4 relative z-[1]">
          <TopBar title="" link="/service" />

          <div className="flex justify-center mt-5 ">
            <div
              className={`thank__bg w-full max-w-[700px] rounded-3xl p-[20px] py-[50px] sm:p-[70px] relative text-center border border-active-0`}
            >
              <h2 className="font-bold text-[28px] sm:text-[32px] text-center text-active-0">
              {t("thank-1")}
               
              </h2>
              <p className="mt-8">{t("thank-2")}</p>
              

              <div className="flex justify-center">
                <div className="mt-8 flex gap-[15px] justify-center flex-col">
                <img className=" w-[200px] m-auto" src={Logo} />
                  <a
                    href="/"
                    className="btn__cons w-[250px] h-[60px] flex justify-center items-center border border-[#1d1f22] hover:border-white rounded-full font-bold text-[15px]"
                  >
                    {t("thank-3")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ThankYou;
