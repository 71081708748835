import SwiperBtnObod from "../assets/img/swiper-btn-obod.svg";
import SwiperBtnNext from "../assets/img/swiper-btn-next.svg";

function TopBar({ title, link }) {
    
    return (
        <>
        <div className=" flex gap-10 relative items-center justify-between sm:justify-start">
            <a href={link} className=" inline-block w-[62px] h-[62px] relative">
              <div className="prev left-0 ">
                <div className="Elips1"></div>
                <div className="Elips2"></div>
                <img src={SwiperBtnObod} className="Elips3" />
                <img src={SwiperBtnNext} className="sw__arrow" />
              </div>
            </a>
            <h1 className=" font-kalinov text-active-0 text-3xl sm:text-4xl whitespace-nowrap">
            {title}
            </h1>
            <div className="w-[30px] sm:hidden"></div>
          </div>
        </>
      )
}

export default TopBar