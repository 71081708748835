import TopBar from "../../Components/TopBar";

// import Contact1 from "../../assets/img/Contact/contact1.png";
import ContactMail from "../../assets/img/Contact/contactMail.svg";
import contactTelegram from "../../assets/img/Contact/contactTelegram.svg";
import { useTranslation } from "react-i18next";
// import HeadImg from "../../assets/img/head__img.png";
import Head2 from "../../assets/img/head2.png";

function Contacts() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className=" linear__bg-energy h-full pt-10 pb-20 relative m-0 main__bg">
        <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
          <TopBar title={t("contacts-topBar")} link="/service" />
          <img
              className=" absolute z-0 left-[0%] max-h-[700px] object-contain"
              src={Head2}
              alt="" />
          <div className="grid grid-cols-12 gap-[0]">
          
            <div className=" order-1 sm:order-0 col-span-12 sm:col-span-12 z-[1] mt-[430px] sm:mt-[380px]">
              <p className=" pt-10 sm:pt-20 text-[16px] sm:text-xl text-center">
                {t("contacts-text")}

              </p>
              <div className=" flex flex-col items-center pt-6 sm:pt-16 gap-5">
                <a
                  className=" flex justify-top items-center  gap-5 bg-active-0 border border-active-0 rounded-full hover:bg-opacity-0 w-[340px] h-[86px] px-5 text-center"
                  href="https://t.me/RostiSey"
                >
                  <img className="w-[50px]" src={contactTelegram} />
                  <p className=" text-1xl">Telegram</p>
                </a>
                <a
                  className=" flex justify-top items-center  gap-5 bg-active-0 border border-active-0 rounded-full hover:bg-opacity-0 w-[340px] h-[86px] px-5 text-center"
                  href="mailto:RostislavSensey@gmail.com"
                >
                  <img className="w-[50px] " src={ContactMail} />
                  <p className=" text-[16px]">RostislavSensey@gmail.com</p>
                </a>
              </div>
            </div>
            <div className="col-span-12  sm:col-span-6  items-center flex mt-5 sm:mt-0 animate__animated animate__fadeIn ">
              {/* <img
                className="  rounded-[80px] object-contain max-w-[500px] "
                src={Contact1}
              /> */}
              {/* <img
                className="  rounded-[10px] object-contain max-w-[500px] "
                src={HeadImg}
              /> */}
              
              
            </div>
          </div>
          <div className="flex justify-center w-full">
            <a href="/service" className="button__main mt-8 sm:mt-10 ">
              <div className=" w-full h-full flex justify-center items-center text-white font-bold hover:text-red-700 transition-all duration-300">
                {t("Services")}
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contacts;
