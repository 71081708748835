// import { useState, useRef, useEffect } from "react";
// import SwiperBtnNext from "../../assets/img/swiper-btn-next.svg";
// import SwiperBtnObod from "../../assets/img/swiper-btn-obod.svg";
// import EnergyF from "../../assets/img/Energy-healing.png";
// import Energy1 from "../../assets/img/Energy-healing1.svg";
// import Energy2 from "../../assets/img/Energy-healing2.svg";
// import Energy3 from "../../assets/img/energy3.png";
// import White1 from "../../assets/img/white.png";
// import SwiperBtn from "../../assets/img/swiper-btn.svg";
// import Sertificat from "../../assets/img/sertificat.png";

// import Buttons from "../../Components/Buttons";
// import { useTranslation } from "react-i18next";

// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/mousewheel";
// import { EffectCreative, Mousewheel, Navigation } from "swiper/modules";
// import TopBar from "../../Components/TopBar";
// import PopupForm from "../../Components/PopupForm";

// function Energy() {
//   const swiperRef = useRef(null);

//   useEffect(() => {
//     if (swiperRef.current) {
//       swiperRef.current.swiper.slideTo(1); // Переход к второму слайду (индекс 1)
//     }
//   }, []);

//   const goToSlide2 = () => {
//     swiperRef.current.swiper.slideTo(1); // Переход к второму слайду (индекс 1)
//   };

//   const goToSlide3 = () => {
//     swiperRef.current.swiper.slideTo(2); // Переход к третьему слайду (индекс 2)
//   };

//   useEffect(() => {
//     swiperRef.current.swiper.slideTo(0); // Переход к первому слайду (индекс 0)
//   }, []);
  
  
  
//   const { t, i18n } = useTranslation();



//   return (
//     <>
//       <Swiper
//         ref={swiperRef}
//         className="mySwiper2"
//         direction={"vertical"}
//         // grabCursor={true}
//         // mousewheel={true}
//         effect={"creative"}
//         creativeEffect={{
//           prev: {
//             shadow: true,
//             translate: [0, "-20%", -1],
//           },
//           next: {
//             translate: [0, "100%", 0],
//           },
//         }}
//         navigation={{
//           clickable: true,
//           nextEl: ".vertical-next",
//           prevEl: ".vertical-prev",
//         }}
//         modules={[EffectCreative, Mousewheel, Navigation]}
//       >
//         <SwiperSlide className=" relative ">
//           <section className="main__bg h-full pt-10 pb-2 relative m-0 linear__bg-energy">
//             <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
//               {/* head */}
//               <div className=" flex gap-9 relative items-center justify-between sm:justify-start">
//                 <a
//                   href="/service"
//                   className=" inline-block w-[62px] h-[62px] relative"
//                 >
//                   <div className="prev left-0 ">
//                     <div className="Elips1"></div>
//                     <div className="Elips2"></div>
//                     <img src={SwiperBtnObod} className="Elips3" />
//                     <img src={SwiperBtnNext} className="sw__arrow" />
//                   </div>
//                 </a>
//                 <h1 className=" font-kalinov text-active-0 text-1xl sm:text-4xl whitespace-nowrap">
//                   {t("energy-slide1-topBar-1")}
//                   <br />
//                   {t("energy-slide1-topBar-2")}
//                 </h1>
//                 <div className="w-[40px] sm:hidden"></div>
//               </div>
//               {/* head End*/}
//               <div className="grid grid-cols-12 gap-[30px]">
//                 <div className=" col-span-12 sm:col-span-8">
//                   <p className=" pt-6 text-sm sm:text-xl animate__animated  animate__fadeInLeft">
//                     {t("energy-slide1-content")}<br />
//                     <br />
//                     {t("energy-slide1-content2")}<br />
//                     <br />
//                     {t("energy-slide1-content3")}<br />
//                     <br />
//                     {t("energy-slide1-content4")}<br />
//                     <br />
//                     {t("energy-slide1-content5")}<br />
//                     <br />
//                   </p>
//                   <div className="flex gap-[15px] sm:gap-[30px] mt-6 animate__animated  animate__fadeInLeft">
//                     <div
//                       className=" bg-white bg-opacity-[0.07] rounded-3xl relative w-full max-w-[270px] border border-active-0 py-5 sm:py-7 px-2 sm:px-5 font-extrabold uppercase text-center h-[160px] sm:h-[230px] cursor-pointer hover:shadow-2xl hover:bg-opacity-[0.20] transition-all duration-300"
//                       onClick={goToSlide2}
//                     >
//                       <p className=" text-[10px] sm:text-base">
//                         {t("energy-slide1-card-1")}
//                       </p>
//                       <div className=" flex items-center h-full">
//                         <img className="  max-w-16 mx-auto " src={Energy1} />
//                       </div>
//                     </div>

//                     <div
//                       className=" bg-white bg-opacity-[0.07] rounded-3xl relative w-full max-w-[270px] border border-active-0 py-5 sm:py-7 px-2 sm:px-5 font-extrabold uppercase text-center h-[160px] sm:h-[230px] cursor-pointer hover:shadow-2xl hover:bg-opacity-[0.20] transition-all duration-300"
//                       onClick={goToSlide3}
//                     >
//                       <p className=" text-[10px] sm:text-base">
//                         {t("energy-slide1-card-2")}
//                       </p>
//                       <div className=" flex items-center h-full">
//                         <img className="  max-w-20 mx-auto " src={Energy2} />
//                       </div>
//                     </div>
//                   </div>
//                   {/* <div className="flex justify-center sm:block">
//                     <a
//                       href="/reviews"
//                       className=" bg-active-0 rounded-full py-5 px-16 text-black inline-block mt-5 sm:mt-14 font-bold hover:bg-opacity-0 hover:text-white border border-active-0 "
//                     >
//                       {t("Reviews")}
//                     </a>
//                   </div> */}
//                 </div>
//                 <div className=" col-span-4  items-top hidden sm:flex mode ">
//                   <img
//                     className="  rounded-3xl object-contain max-h-[630px] mode animate__animated animate__fadeInUp"
//                     src={EnergyF}
//                   />
//                 </div>
//               </div>
//             </div>
//           </section>
//         </SwiperSlide>

//         <SwiperSlide className=" relative">
//           <section className="main__bg h-full pt-10 pb-2 relative m-0 linear__bg-energy">
//             <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
//               <TopBar title={t("energy-slide2-topBar")} link="/service" />
//               <div className=" sm:flex gap-5">
//                 <p className="pt-8 text-sm sm:text-xl w-full ">
//                   {t("energy-slide2-content-1")}
//                   <br />
//                   <br />
//                   {t("energy-slide2-content-2")} <br />
//                   <br />
//                   {t("energy-slide2-content-3")}
//                 </p>
//                 <div className="w-full  sm:max-w-[370px] flex flex-col justify-center items-center pt-8">
//                   <img className="w-full max-w-[250px] sm:max-w-full opacity-60" src={Sertificat}/>
//                   <div className=" mt-6 sm:mt-4 flex gap-[15px] justify-center sm:justify-start">
                 
//                   <PopupForm buttonText={t("Submit-your")} />
//                 </div>
//                 </div>
                
//               </div>
//             </div>
//           </section>
//         </SwiperSlide>

//         <SwiperSlide className=" relative">
//           <section className="main__bg h-full pt-10 pb-2 relative m-0">
//             <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
//               {/* head */}
//               <div className=" flex gap-9 relative items-center justify-between sm:justify-start">
//                 <a
//                   href="/service"
//                   className=" inline-block w-[62px] h-[62px] relative"
//                 >
//                   <div className="prev left-0 ">
//                     <div className="Elips1"></div>
//                     <div className="Elips2"></div>
//                     <img src={SwiperBtnObod} className="Elips3" />
//                     <img src={SwiperBtnNext} className="sw__arrow" />
//                   </div>
//                 </a>
//                 <h1 className=" font-kalinov text-active-0 text-2xl sm:text-4xl whitespace-nowrap">
//                   {t("energy-slide3-topBar")}
//                 </h1>
//                 <div className="w-[40px] sm:hidden"></div>
//               </div>
//               {/* head End*/}
//               <div className="">
//                 <p className="pt-8 text-sm sm:text-xl">
//                   {t("energy-slide3-content-1")}
//                   <br />
//                   <br />
//                   {t("energy-slide3-content-2")} 
//                   <br/>
//                   <br/>
//                   {t("energy-slide3-content-3")} 
//                 </p>
                
//                 <Buttons />
                
//               </div>
//             </div>
//           </section>
//         </SwiperSlide>
//       </Swiper>
//       <div className="vertical-prev   ">
//         <img src={SwiperBtn} className=" -rotate-90 w-[20px] " />
//       </div>
//       <div className="vertical-next">
//         <img src={SwiperBtn} className=" rotate-90 w-[20px] " />
//       </div>
//     </>
//   );
// }

// export default Energy;
















import SwiperBtnNext from "../../assets/img/swiper-btn-next.svg";
import SwiperBtnObod from "../../assets/img/swiper-btn-obod.svg";


import { useState } from "react";
import SwiperBtn from "../../assets/img/swiper-btn.svg";
import EnergyF from "../../assets/img/Energy-healing.png";
import Energy1 from "../../assets/img/Energy-healing1.svg";
import Energy2 from "../../assets/img/Energy-healing2.svg";
import Sertificat from "../../assets/img/sertificat.png";
import Buttons from "../../Components/Buttons";
import { useTranslation } from "react-i18next";
import TopBar from "../../Components/TopBar";
import PopupForm from "../../Components/PopupForm";

function Energy() {
  const [activeSection, setActiveSection] = useState(1);
  const { t, i18n } = useTranslation();

  const handlePrevClick = () => {
    if (activeSection > 1) {
      setActiveSection(activeSection - 1);
    }
  };

  const handleNextClick = () => {
    if (activeSection < 3) {
      setActiveSection(activeSection + 1);
    }
  };

  const goToSlide2 = () => {
    setActiveSection(2);
  };

  const goToSlide3 = () => {
    setActiveSection(3);
  };

  return (
    <>
      <section
        className={`main__bg  h-full pt-10  relative m-0 pb-10 ${
          activeSection === 1 ? "linear__bg-energy" : "hidden"
        }`}
        style={{ zIndex: activeSection === 1 ? 3 : 1 }}
      >
        {/* Content for section 1 */}
        <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
              {/* head */}
              <div className=" flex gap-9 relative items-center justify-between sm:justify-start">
                <a
                  href="/service"
                  className=" inline-block w-[62px] h-[62px] relative"
                >
                  <div className="prev left-0 ">
                    <div className="Elips1"></div>
                    <div className="Elips2"></div>
                    <img src={SwiperBtnObod} className="Elips3" />
                    <img src={SwiperBtnNext} className="sw__arrow" />
                  </div>
                </a>
                <h1 className=" font-kalinov text-active-0 text-1xl sm:text-4xl whitespace-nowrap">
                  {t("energy-slide1-topBar-1")}
                  <br />
                  {t("energy-slide1-topBar-2")}
                </h1>
                <div className="w-[40px] sm:hidden"></div>
              </div>
              {/* head End*/}
              <div className="grid grid-cols-12 gap-[30px]">
                <div className=" col-span-12 sm:col-span-8">
                  <p className=" pt-6 text-sm sm:text-xl animate__animated  animate__fadeInLeft">
                    {t("energy-slide1-content")}<br />
                    <br />
                    {t("energy-slide1-content2")}<br />
                    <br />
                    {t("energy-slide1-content3")}<br />
                    <br />
                    {t("energy-slide1-content4")}<br />
                    <br />
                    {t("energy-slide1-content5")}<br />
                    <br />
                  </p>
                  <div className="flex gap-[15px] sm:gap-[30px] mt-6 animate__animated  animate__fadeInLeft">
                    <div
                      className=" bg-white bg-opacity-[0.07] rounded-3xl relative w-full max-w-[270px] border border-active-0 py-5 sm:py-7 px-2 sm:px-5 font-extrabold uppercase text-center h-[160px] sm:h-[230px] cursor-pointer hover:shadow-2xl hover:bg-opacity-[0.20] transition-all duration-300"
                      onClick={goToSlide2}
                    >
                      <p className=" text-[10px] sm:text-base">
                        {t("energy-slide1-card-1")}
                      </p>
                      <div className=" flex items-center h-full">
                        <img className="  max-w-16 mx-auto " src={Energy1} />
                      </div>
                    </div>

                    <div
                      className=" bg-white bg-opacity-[0.07] rounded-3xl relative w-full max-w-[270px] border border-active-0 py-5 sm:py-7 px-2 sm:px-5 font-extrabold uppercase text-center h-[160px] sm:h-[230px] cursor-pointer hover:shadow-2xl hover:bg-opacity-[0.20] transition-all duration-300"
                      onClick={goToSlide3}
                    >
                      <p className=" text-[10px] sm:text-base">
                        {t("energy-slide1-card-2")}
                      </p>
                      <div className=" flex items-center h-full">
                        <img className="  max-w-20 mx-auto " src={Energy2} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-span-4  items-top hidden sm:flex mode ">
                  <img
                    className="  rounded-3xl object-contain max-h-[630px] mode animate__animated animate__fadeInUp"
                    src={EnergyF}
                  />
                </div>
              </div>
            </div>
      </section>

      <section
        className={`main__bg h-full pt-10 relative m-0 pb-10 ${
          activeSection === 2 ? "linear__bg-energy" : "hidden"
        }`}
        style={{ zIndex: activeSection === 2 ? 3 : 1 }}
      >
        {/* Content for section 2 */}
        <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
              <TopBar title={t("energy-slide2-topBar")} link="/service" />
              <div className=" sm:flex gap-5">
                <p className="pt-8 text-sm sm:text-xl w-full ">
                  {t("energy-slide2-content-1")}
                  <br />
                  <br />
                  {t("energy-slide2-content-2")} <br />
                  <br />
                  {t("energy-slide2-content-3")}
                </p>
                <div className="w-full  sm:max-w-[370px] flex flex-col justify-center items-center pt-8">
                  <img className="w-full max-w-[250px] sm:max-w-full opacity-60" src={Sertificat}/>
                  <div className=" mt-6 sm:mt-4 flex gap-[15px] justify-center sm:justify-start">
                 
                  <PopupForm buttonText={t("Submit-your")} />
                </div>
                </div>
                
              </div>
            </div>
      </section>

      <section
        className={`main__bg h-full pt-10 relative m-0 bg-red-500 pb-10 ${
          activeSection === 3 ? "linear__bg-energy" : "hidden"
        }`}
        style={{ zIndex: activeSection === 3 ? 3 : 1 }}
      >
        {/* Content for section 3 */}
        <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
              {/* head */}
              <div className=" flex gap-9 relative items-center justify-between sm:justify-start">
                <a
                  href="/service"
                  className=" inline-block w-[62px] h-[62px] relative"
                >
                  <div className="prev left-0 ">
                    <div className="Elips1"></div>
                    <div className="Elips2"></div>
                    <img src={SwiperBtnObod} className="Elips3" />
                    <img src={SwiperBtnNext} className="sw__arrow" />
                  </div>
                </a>
                <h1 className=" font-kalinov text-active-0 text-2xl sm:text-4xl whitespace-nowrap">
                  {t("energy-slide3-topBar")}
                </h1>
                <div className="w-[40px] sm:hidden"></div>
              </div>
              {/* head End*/}
              <div className="">
                <p className="pt-8 text-sm sm:text-xl">
                  {t("energy-slide3-content-1")}
                  <br />
                  <br />
                  {t("energy-slide3-content-2")} 
                  <br/>
                  <br/>
                  {t("energy-slide3-content-3")} 
                </p>
                
                <Buttons />
                
              </div>
            </div>
      </section>

      <div className="vertical-prev" onClick={handlePrevClick} style={{ zIndex: 4, opacity: activeSection === 3 ? 1 : 0.6 }}>
        <img src={SwiperBtn} className="-rotate-90 w-[20px]" />
      </div>
      <div className="vertical-next" onClick={handleNextClick} style={{ zIndex: 4, opacity: activeSection === 1 ? 1 : 0.6 }}>
        <img src={SwiperBtn} className="rotate-90 w-[20px]" />
      </div>
    </>
  );
}

export default Energy;


