import React, { useState, useEffect } from "react";
import Preloader from "./Preloader";

function withLoader(Component, shouldShowLoader) {
  return function WithLoaderWrapper(props) {
    const [componentLoaded, setComponentLoaded] = useState(false);
    const [preloaderVisible, setPreloaderVisible] = useState(true);

    useEffect(() => {
      // Имитация задержки загрузки компонента
      const componentTimeout = setTimeout(() => {
        setComponentLoaded(true);
      }, 0); //4000 Установите время задержки по вашему усмотрению

      // Очистка таймаута при размонтировании компонента
      return () => clearTimeout(componentTimeout);
    }, []);

    useEffect(() => {
      // Имитация задержки отображения прелоадера
      const preloaderTimeout = setTimeout(() => {
        setPreloaderVisible(false);
      }, 4000); //4000 Установите время задержки по вашему усмотрению

      // Очистка таймаута при размонтировании компонента
      return () => clearTimeout(preloaderTimeout);
    }, []);

    const shouldDisplayLoader = typeof shouldShowLoader === "function" ? shouldShowLoader(props) : shouldShowLoader;

    return (
      <>
        {shouldDisplayLoader && preloaderVisible && <Preloader />}
        {componentLoaded && <Component {...props} />}
      </>
    );
  };
}

export default withLoader;


