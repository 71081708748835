import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Headerlogo from "../assets/img/header-logo.svg";

import { useTranslation } from "react-i18next";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        event.target !== menuRef.current
      ) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <header className=" header__bg  flex justify-center px-4 fixed w-full z-10">
        <div className="container flex items-center justify-between h-[60px] sm:h-20">
          <a href="/">
            <img src={Headerlogo} alt="Logo" className=" cursor-pointer" />
          </a>

          <div className="flex items-center gap-[30px]">
            <div className="language-dropdown">
              <button className="language-button">
                {i18n.language.toUpperCase()}
              </button>
              <ul className="language-list">
                <li
                  style={{ display: i18n.language === "ua" ? "none" : "block" }}
                >
                  <button onClick={() => changeLanguage("ua")}>
                    {t("UA")}
                  </button>
                </li>
                <li
                  style={{ display: i18n.language === "en" ? "none" : "block" }}
                >
                  <button onClick={() => changeLanguage("en")}>
                    {t("ENG")}
                  </button>
                </li>
                <li
                  style={{ display: i18n.language === "ru" ? "none" : "block" }}
                >
                  <button onClick={() => changeLanguage("ru")}>
                    {t("RU")}
                  </button>
                </li>
              </ul>
            </div>

            <nav className="" ref={menuRef}>
              <div
                className="bg-[#C8A26D] w-16 sm:w-24 flex justify-center rounded-2xl sm:rounded-full cursor-pointer"
                onClick={toggleMenu}
              >
                <div className="relative w-9 sm:w-11 h-11">
                  <span
                    className={`absolute w-full bg-white h-0.5 top-1/2 left-0 rounded-xl transition-all duration-100 ${
                      isMenuOpen ? "hidden" : ""
                    }`}
                  ></span>
                  <span
                    className={`absolute w-full bg-white h-0.5 top-1/2 left-0 rounded-xl transition-all duration-300  ${
                      isMenuOpen ? "mt-0" : "-mt-3"
                    }`}
                    style={{
                      transform: `${isMenuOpen ? "rotate(-45deg)" : ""}`,
                    }}
                  ></span>
                  <span
                    className={`absolute w-full bg-white h-0.5 top-1/2 left-0 rounded-xl   transition-all duration-300    ${
                      isMenuOpen ? "mt-0" : "mt-3"
                    }`}
                    style={{
                      transform: `${isMenuOpen ? "rotate(45deg)" : ""}`,
                    }}
                  ></span>
                </div>
              </div>
              <ul
                className={`bg-[#27292E] py-5 px-10 rounded-l-2xl sm:rounded-2xl absolute right-0 top-16 ${
                  isMenuOpen ? "" : "hidden"
                }`}
              >
                <li>
                  <Link to="/" className="manu__text" onClick={closeMenu}>
                    {t("home")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/energy"
                    className="manu__text "
                    onClick={closeMenu}
                  >
                    {t("Energy")}
                  </Link>
                </li>
                <li>
                  <Link to="/rpt" className="manu__text " onClick={closeMenu}>
                    {t("rpt")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/retreats"
                    className="manu__text "
                    onClick={closeMenu}
                  >
                    {t("Retreats")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contacts"
                    className="manu__text "
                    onClick={closeMenu}
                  >
                    {t("contacts")}
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/consultation"
                    className="manu__text "
                    onClick={closeMenu}
                  >
                    {t("consultation")}
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
