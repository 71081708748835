import { Link } from "react-router-dom";

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import TopBar from "../../Components/TopBar";
import SwiperBtn from "../../assets/img/swiper-btn.svg";
import SwiperBtnNext from "../../assets/img/swiper-btn-next.svg";
import SwiperBtnObod from "../../assets/img/swiper-btn-obod.svg";
import Rpt1 from "../../assets/img/rpt/rtp1.png";
import Rpt2 from "../../assets/img/rpt/rtp2.png";
import { useTranslation } from "react-i18next";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
function Rpt() {
  const { t, i18n } = useTranslation();
    return (
        <>
      <section className=" pt-10 pb-20 relative bg-[#191A1D]">
        <div className=" container pt-10 sm:pt-16 px-4 ">
        <TopBar title={t("rpt-slide1-topBar")} link="/service"/>

          <div className=" relative ">
            <Swiper
              className="mySwiper3"
              modules={[Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              navigation={{
                clickable: true,
                nextEl: ".swiper-button-next__custom",
                prevEl: ".swiper-button-prev__custom",
              }}
              spaceBetween={100}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
              }}
            >
              <SwiperSlide className=" rounded-3xl sm:max-w-[370px] animate__animated animate__fadeInLeft">
                <a href="/rpt-focused" className="servis__slide">
                  <img
                    src={Rpt1}
                    className=" rounded-2xl h-full sm:max-h-[416px] object-cover absolute sm:relative "
                  />
                  <h2 className=" relative pt-12 text-2xl z-[1] block px-5 sm:px-0 font-bold">
                  {t("rpt-slide1-title")}
                  </h2>
                  <p className=" relative pt-4 z-[1] block px-5 sm:px-0 pb-5 text-sm sm:text-base">
                  {t("rpt-slide1-text")}
                  </p>
                </a>
              </SwiperSlide>

              <SwiperSlide className=" rounded-3xl sm:max-w-[370px] animate__animated animate__fadeInRight">
                <a href="/rpt-comprehensive" className="servis__slide">
                  <img
                    src={Rpt2}
                    className=" rounded-2xl h-full sm:max-h-[416px] object-cover absolute sm:relative "
                  />
                  <h2 className=" relative pt-12 text-2xl z-[1] block px-5 sm:px-0 font-bold">
                  {t("rpt-slide2-title")}
                  </h2>
                  <p className=" relative pt-4 z-[1] block px-5 sm:px-0 pb-5 text-sm sm:text-base">
                  {t("rpt-slide2-text")}
                  </p>
                </a>
              </SwiperSlide>

             

              
            </Swiper>
            <div className="swiper-button-prev__custom prev">
              <div className="Elips1"></div>
              <div className="Elips2"></div>
              <img src={SwiperBtnObod} className="Elips3" />
              <img src={SwiperBtnNext} className="sw__arrow" />
            </div>
            <div className="swiper-button-next__custom next">
              <div className="Elips1"></div>
              <div className="Elips2"></div>
              <img src={SwiperBtnObod} className="Elips3" />
              <img src={SwiperBtn} className="sw__arrow" />
            </div>
          </div>
        </div>
      </section>
    </>
      )
}

export default Rpt