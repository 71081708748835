// import Buttons from "../../Components/Buttons";
// import TopBar from "../../Components/TopBar";
import { useTranslation } from "react-i18next";
import SwiperBtnObod from "../../assets/img/swiper-btn-obod.svg";
import SwiperBtnNext from "../../assets/img/swiper-btn-next.svg";
import Reviews10 from "../../assets/img/Reviews/Reviews1.svg";
import Anons1 from "../../assets/img/Retreats/anons1.svg";
import Anons2 from "../../assets/img/Retreats/anons2.svg";
import Anons3 from "../../assets/img/Retreats/anons3.svg";
import Anons4 from "../../assets/img/Retreats/anons4.svg";

function Anons() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className=" h-full pt-10 pb-2 relative m-0 bg-[#191A1D]">
        <div className=" container pt-10 sm:pt-16 px-4 relative z-[1]">
          <div className=" flex gap-10 relative items-center justify-between sm:justify-start">
            <a href="/rpt" className=" inline-block w-[62px] h-[62px] relative">
              <div className="prev left-0 ">
                <div className="Elips1"></div>
                <div className="Elips2"></div>
                <img src={SwiperBtnObod} className="Elips3" />
                <img src={SwiperBtnNext} className="sw__arrow" />
              </div>
            </a>
            <h1 className=" font-kalinov text-active-0 text-2xl sm:text-4xl whitespace-nowrap">
              {t("anons-topBar-1")} <br className="sm:hidden" />
              {t("anons-topBar-2")}
            </h1>
            <div className="w-[30px] sm:hidden"></div>
          </div>

          <div className="grid grid-cols-12 gap-[0]">
            <div className=" col-span-12 sm:col-span-5 z-[1]">
              <p className="mt-10 pt-8 text-active-0 font-bold text-[20px] max-w-[280px] leading-8">
                {t("anons-text-top")}
              </p>
              <div className="mt-10 ">
                <div className="flex gap-[20px] mt-4">
                    <img
                    className=" w-auto "
                    src={Anons1}
                    />
                    <p className=" text-[20px] font-bold ">{t("anons-text-1")}</p>
                </div>
                <div className="flex gap-[20px] mt-4">
                    <img
                    className=" w-auto "
                    src={Anons2}
                    />
                    <p className=" text-[20px] font-bold ">{t("anons-text-2")}</p>
                </div>
                <div className="flex gap-[20px] mt-4">
                    <img
                    className=" w-auto "
                    src={Anons3}
                    />
                    <p className=" text-[20px] font-bold ">{t("anons-text-3")}</p>
                </div>
                <div className="flex gap-[20px] mt-4">
                    <img
                    className=" w-auto "
                    src={Anons4}
                    />
                    <p className=" text-[20px] font-bold ">{t("anons-text-4")}</p>
                </div>
              </div>

              <div className=" mt-6 sm:mt-5 flex gap-[15px] justify-center sm:justify-start">
                <div className=" mt-2 sm:mt-2 flex gap-[15px] justify-center sm:justify-start">
                  <a
                    className=" btn__cons w-[240px] h-[44px] flex justify-center items-center border border-active-0 hover:border-white rounded-full font-bold text-[15px]"
                    href="/contacts"
                  >
                    {t("anons-button")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-12  sm:col-span-7  items-center flex mt-5 sm:mt-0">
              <img
                className="  rounded-3xl object-contain max-h-[450px] sm:max-h-[700px] absolute sm:relative top-[45%] right-[-40%] sm:top-0 sm:right-0 z-0"
                src={Reviews10}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Anons;
