import PopupForm from "./PopupForm";
import { useTranslation } from "react-i18next";


function Buttons() {
  const { t, i18n } = useTranslation();
  return (
    <>
    <div className=" mt-10 sm:mt-16 flex gap-[15px] justify-center sm:justify-start">
        <a
        className="w-[164px] h-[44px] flex justify-center items-center border border-active-0 rounded-full font-bold text-[15px] hover:bg-active-0 hover:text-black"
        href="/reviews"
        >{t("Reviews")}</a>
        <PopupForm buttonText={t("Consultation")} />
    </div>
    
    </>
  );
}

export default Buttons;
